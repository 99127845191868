const aws = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:ad9576fc-517e-42ca-aeea-52040d811573',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_toSYfCEF5',
  aws_user_pools_web_client_id: '5g1dvgl605dhfvkacge7992bdj',
  aws_appsync_graphqlEndpoint:
    'https://mp47uy4ws5h4djgmknd3g5p2fa.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_portal_graphqlEndpoint:
    'https://cikrsw6itvgdlce3cynpnhwlay.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: null,
  oauth: {},
  Auth: {
    identityPoolId: 'eu-west-1:ad9576fc-517e-42ca-aeea-52040d811573',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_toSYfCEF5',
    userPoolWebClientId: '5g1dvgl605dhfvkacge7992bdj'
  }
}
export default aws
